import React, { useEffect, useState } from "react";
import { Frontend_URL } from "../../environment";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";

function DataTrendPage(props) {
  const {
    count,
    errorOfFile,
    selectedMethod,
    setSelectedMethod,
    confidenceText,
    showConfidenceText,
    onDrop,
    file,
    errors,
    preWhitening,
    setPreWhitening,
    bootstrap,
    setBootstrap,
    incrementCount,
    validateSubmit,
    decrementCount,
    trackClick
  } = props;


  return (
    <div
      id="bg-graph-img"
      className="main-graph-image"
      style={{ backgroundImage: `url(${Frontend_URL}img/runAlgo.png)` }}
      onClick={(e)=>trackClick(e)}
    >
      <div className="heading">
        <h1>Import Data</h1>
      </div>

      <div className="">
        <div className="">
          <div className="lg:py-6 lg:pr-16">
            <div className="flex  ">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                    1
                  </div>
                </div>
                <div className="w-px h-full bg-gray-500 border-steps" />
              </div>
              <div className="pt-1 pb-8 dropzone-part">
                <p className="mb-2 text-lg font-bold select-steps-contnet ">
                  Select import file
                </p>
                <div className="dropzone-content">
                  <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <label
                            htmlFor="file-input-area"
                            className=" fileupload"
                          >
                            Upload
                          </label>
                          <p className="errorMsg">{errorOfFile}</p>
                        </div>

                        <p className="errorMsg">{errors.file || null}</p>
                      </>
                    )}
                  </Dropzone>
                  <span className="dataText"> {file?.name}</span>
                  {/* </p> */}
                </div>
              </div>
            </div>
            <div className="flex part2">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                    2
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300  border-steps" />
              </div>
              <div className="pt-1 pb-8 ">
                <p className="mb-2 text-lg font-bold select-steps-contnet">
                  Select method
                </p>
                {/* <p className="text-gray-700 upload-data-inputdata"> */}
                <div className="methods">
                  <select
                    className="method-select"
                    value={selectedMethod}
                    onChange={(event) => setSelectedMethod(event.target.value)}
                    required
                  >
                    <option value="Hamed Rao Test">Hamed Rao Test</option>
                    <option value="Hamed Rao Test">Hamed Rao v2</option>
                    <option value="Hamed Rao Test">Hamed Rao v3</option>
                    <option value="Hamed Rao Test">Hamed Rao v4</option>
                    <option value="Yue Wang Test">Yue Wang Test</option>
                    <option value="Yue Wang Test">Yue Wang Test</option>
                    <option value="Yue Wang Test">Yue Wang v3</option>
                    <option value="Yue Wang Test">Yue Wang v4</option>
                  </select>
                </div>
                {/* </p> */}
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                    3
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300  border-steps" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold select-steps-contnet">
                  Pre-processing
                </p>
                {/* <p className="text-gray-700 upload-data-inputdata"> */}
                <div className="toggle-switch">
                  <label className="select-toggle-btn">Prewhitening</label>
                  <label class="switch">
                    <input
                      checked={preWhitening}
                      type="checkbox"
                      class="switch-input"
                    />
                    <span
                      onClick={(e) => setPreWhitening(!preWhitening)}
                      class="switch-label"
                      data-on="On"
                      data-off="Off"
                    ></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>

                <div className="toggle-switch">
                  <label className="select-toggle-btn">Bootstrap</label>
                  <label class="switch">
                    <input
                      checked={bootstrap}
                      type="checkbox"
                      class="switch-input"
                    />
                    <span
                      onClick={(e) => setBootstrap(!bootstrap)}
                      class="switch-label"
                      data-on="On"
                      data-off="Off"
                    ></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
                {/* </p> */}
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                    4
                  </div>
                </div>
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-1 text-lg font-bold select-steps-contnet">
                  Confidence
                </p>
                <p className="text-gray-700">
                  <div>
                    <div className="plus-minus-input">
                      <button
                        type="button"
                        className="decrement-button"
                        onClick={decrementCount}
                      >
                        -
                      </button>
                      <span className="count-persanttage">{count}%</span>

                      <button
                        type="button"
                        className="increment-button"
                        onClick={incrementCount}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {showConfidenceText ? (
                    <span>{confidenceText}</span>
                  ) : (
                    <span style={{ opacity: 0 }}>valid</span>
                  )}
                </p>
              </div>
            </div>
            <div className="spaceonright">
              <button
                className="submitbtn"
                type="button"
                onClick={(e) => validateSubmit(e)}
              >
                Run
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataTrendPage;
