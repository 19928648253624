import axios from "axios";
import { BACKEND_URL } from "../../environment";
import api from "../../utils/api";

import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
} from "../Constants/adminconstants";

export const getUserListReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };

    case USER_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        userListData: action.payload,
        message: action.payload.message,
      };

    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case    USER_UPDATE_REQUEST:
        return { loading: true };
  
      case   USER_UPDATE_SUCCESS:
        return {
          loading: false,
          userupdateData: action.payload,
          success:true
        };
  
      case    USER_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
