import React, { useRef } from "react";
import html2canvas from "html2canvas";
import { useSelector, useDispatch } from "react-redux";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
  ReferenceLine,
  BarChart,
  Bar,
  Cell,
  Legend,
} from "recharts";
import { Link } from "react-router-dom";

const ResultPage = () => {
  const domRef = useRef();
  const domRef2 = useRef();
  // plotting
  const data = [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 260 },
    { x: 170, y: 300, z: 400 },
    { x: 140, y: 250, z: 280 },
    { x: 150, y: 400, z: 500 },
    { x: 110, y: 280, z: 200 },
  ];
  //   confidence

  const dataConfidence = [
    {
      x: -50,
      y: 0,
    },
    {
      x: -50,
      y: -50,
    },
    {
      x: -0,
      y: -50,
    },
    {
      x: 0,
      y: 0,
    },
    {
      x: 50,
      y: 0,
    },
    {
      x: 50,
      y: 50,
    },
    {
      x: 0,
      y: 50,
    },
  ];
  const minX = Math.min(...data.map((d) => d.x));
  const minY = Math.min(...data.map((d) => d.y));

  const histogramData = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  // download image

  const handleDownload = () => {
    html2canvas(domRef.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "image.png";
      downloadLink.click();
    });
  };
  const handleConfidenceDownload = () => {
    html2canvas(domRef2.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "image.png";
      downloadLink.click();
    });
  };

  const {
    success: successAdd,
    addfiledata,
    message: addmessage,
    error: errorOfFile,
  } = useSelector((state) => state.newTextFileReducer);

  const {
    success: saveSuccess,

    message: saveMessage,
    error: errorOfSaveFile,
    saveFileData,
  } = useSelector((state) => state.saveNewFileReducer);
  return (
    <div className="dataresult ">
      <div className="datashow">
        <h2 className="dataheading">Data</h2>
        <p className="dataTitle">File</p>
        <p className="dataText">file.txt</p>
        <p className="dataTitle">Method</p>
        <p className="dataText">MKOsOriginalsMann-Kendall</p>
        <p className="dataTitle">Pre-processing</p>
        <p className="dataText">Prewhitenings•sOFF</p>
        <p className="dataText"> Bootstraps•sON</p>
        <div className="addpagelink">
          <Link to={"/upload-file"}>Change</Link>
        </div>
      </div>
      <div className="result">
        <div className="Data-plotting" ref={domRef}>
          <ResponsiveContainer width="100%" height={280}>
            <ScatterChart
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid />
              <XAxis type="number" dataKey="x" name="stature" unit="cm" />
              <YAxis type="number" dataKey="y" name="weight" unit="kg" />
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              <Scatter name="A school" data={data} fill="#8884d8" />
            </ScatterChart>
          </ResponsiveContainer>

          <div className="graphdetail">
            <h2 className="corelate">
              [Corelated-in time ['I'-button; 0-1-scale]
            </h2>
            <img
              onClick={handleDownload}
              className="icondown"
              src="/img/downloadIcon.jpg"
            />
          </div>
        </div>
        <div className="confidenceContainer">
          <div className="confidence" ref={domRef2}>
            <ResponsiveContainer minWidth="100%" height={280}>
              <AreaChart
                margin={{
                  top: 10,
                  right: 20,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />

                <YAxis
                  dataKey="y"
                  domain={["auto", "auto"]}
                  type="number"
                  interval={0}
                  label={{
                    value: `y`,
                    style: { textAnchor: "middle" },
                    angle: -90,
                    position: "left",
                    offset: 0,
                  }}
                  allowDataOverflow={true}
                  strokeWidth={minX < 0 ? 0 : 1}
                />

                <XAxis
                  dataKey="x"
                  domain={["auto", "auto"]}
                  interval={0}
                  type="number"
                  label={{
                    key: "xAxisLabel",
                    value: "x",
                    position: "bottom",
                  }}
                  allowDataOverflow={true}
                  strokeWidth={minY < 0 ? 0 : 1}
                />

                {minY < 0 && (
                  <ReferenceLine
                    y={0}
                    stroke="gray"
                    strokeWidth={1.5}
                    strokeOpacity={0.65}
                  />
                )}
                {minX < 0 && (
                  <ReferenceLine
                    x={0}
                    stroke="gray"
                    strokeWidth={1.5}
                    strokeOpacity={0.65}
                  />
                )}

                <Area
                  strokeWidth={2}
                  data={dataConfidence}
                  dot={false}
                  type="monotone"
                  dataKey="y"
                  stroke="black"
                  tooltipType="none"
                />
              </AreaChart>
            </ResponsiveContainer>
            <img
              onClick={handleConfidenceDownload}
              className="icondown"
              src="/img/downloadIcon.jpg"
            />
          </div>
          <div className="clusterPlot">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart width="100%" height={90} data={histogramData}>
                <XAxis dataKey="name" />
                <YAxis dataKey="amt" />
                <Bar dataKey="uv" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResultPage;
