import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import swal from "sweetalert";
import { registerData } from "../Redux/Action/authentication-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validations } from "../utils";
// import LoaderSpinner from "../Loader-spinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { success, error, message, loading } = useSelector(
    (state) => state.RegisterReducer
  );

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [rerender, setRerender] = useState(false);

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    username: null,
    email: null,
    mobile: null,
    password: null,
    cpassword: null,
  });

  const formData = new FormData();

  const validateSubmit = (e) => {
    e.preventDefault();
    const tempErrors = {
      // first_name: validations.firstName(first_name),
      // last_name: validations.lastName(last_name),
      username: validations.username(username),
      email: validations.email(email),
      // mobile: validations.phoneNumber(mobile),
      password: validations.password(password),
      cpassword: validations.confirmPassword(password, cpassword),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    SubmitHandler();
  };

  const SubmitHandler = () => {
    // formData.append("first_name", first_name);
    // formData.append("last_name", last_name);
    formData.append("username", username);
    formData.append("email", email);
    // formData.append("mobile_number", mobile);
    formData.append("password", password);
    formData.append("confirm_password", cpassword);
    // formData.append("role", 1);
    dispatch(registerData(formData));

    setRerender(true);
  };

  useEffect(() => {
    if (success && rerender) {
      swal({
        title: " ",
        text: message,
        className: "successAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setUserName("");
      setEmail("");
      setMobile("");
      setPassword("");
      setCpassword("");
      setRerender(false);
      navigate("/thank-you");
    }
    if (error && rerender) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 2000,
      });
      setRerender(false);
    }
  }, [success, error, rerender]);

  return (
    <>
      <div className="Auth-main-div">
        <section
          className="vh-100 gradient-form"
          style={{ backgroundColor: "#42c1f4" }}
        >
          <div className="container h-100 Auth-size-register">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="Registerpage text-black">
                <form className="loginpgae">
                  <div className="cms-logo">
                    <img className="cms-login-logo" src="/img/logo.png" />
                    <h2 className="signtitle">Sign Up</h2>
                  </div>
                  <p className="create-text">Create your account</p>
                  <div className="auth-divide-section">
                    <div
                      className={
                        errors.username ? "inputCntnr error" : "inputCntnr"
                      }
                    >
                      <div className="form-outline mb-2">
                        <label className="form-label" htmlFor="form2Example22">
                          User Name:
                        </label>
                        <input
                          type="text"
                          id="form2Example22"
                          className="form-control"
                          value={username}
                          onChange={(e) => {
                            setUserName(e.target.value);
                            setErrors({ ...errors, username: null });
                          }}
                        />
                        <span
                          style={{
                            color: "#D14F4F",
                            float: "right",
                            fontSize: "13px",
                            opacity: errors.username ? 1 : 0,
                          }}
                        >
                          {errors.username ?? "valid"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="auth-divide-section">
                    <div
                      className={
                        errors.email ? "inputCntnr error" : "inputCntnr"
                      }
                    >
                      <div className="form-outline mb-2">
                        <label className="form-label" htmlFor="form2Example11">
                          Email:
                        </label>
                        <input
                          type="email"
                          id="form2Example11"
                          className="form-control"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors({ ...errors, email: null });
                          }}
                        />
                        <span
                          style={{
                            color: "#D14F4F",
                            float: "right",
                            fontSize: "13px",
                            opacity: errors.email ? 1 : 0,
                          }}
                        >
                          {errors.email ?? "valid"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="auth-divide-section">
                    <div
                      className={
                        errors.password ? "inputCntnr error" : "inputCntnr"
                      }
                    >
                      <div className="form-outline mb-2">
                        <label className="form-label" htmlFor="form2Example22">
                          Password:
                        </label>
                        <input
                          type="password"
                          id="form2Example22"
                          className="form-control"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors({ ...errors, password: null });
                          }}
                        />
                        <span
                          style={{
                            color: "#D14F4F",
                            float: "right",
                            fontSize: "13px",
                            opacity: errors.password ? 1 : 0,
                          }}
                        >
                          {errors.password ?? "valid"}
                        </span>
                      </div>
                    </div>
                    <div
                      className={
                        errors.cpassword ? "inputCntnr error" : "inputCntnr"
                      }
                    >
                      <div className="form-outline mb-2">
                        <label className="form-label" htmlFor="form2Example11">
                          Confirm Password:
                        </label>
                        <input
                          type="password"
                          id="form2Example11"
                          className="form-control"
                          value={cpassword}
                          onChange={(e) => {
                            setCpassword(e.target.value);
                            setErrors({ ...errors, cpassword: null });
                          }}
                        />
                        <span
                          style={{
                            color: "#D14F4F",
                            float: "right",
                            fontSize: "13px",
                            opacity: errors.cpassword ? 1 : 0,
                          }}
                        >
                          {errors.cpassword ?? "valid"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="pt-1 pb-1 Account-sec">
                    <button
                      onClick={validateSubmit}
                      className="Auth-button Createpagebtn"
                    >
                      Create Account
                    </button>
                  </div>

                  <div className="d-flex align-items-center justify-content-center pb-4 accounttext">
                    <p className="mb-0 me-2">Already have an account?</p>

                    <div className="loginbotm">
                      <Link className="loginbtnbuttom" to="/login">
                        LOGIN
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Register;
