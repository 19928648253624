import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { resetForgotPassword } from "../Redux/Action/authentication-action";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { validations } from "../utils";
import { Helmet } from "react-helmet";

const Reset_password = () => {
  const { token, uid } = useParams();

  const { success, error } = useSelector((state) => state.resetForgotPasswordReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formData = new FormData();
  const [showPassword, setShowPassword] = useState(false);

  const [newpassword, setnewpassword] = useState("");
  const [cnewpassword, setCnewpassword] = useState("");
  const [rerender, setRerender] = useState(false);

  const [errors, setErrors] = useState({
    newpassword: null,
    cnewpassword: null,
  });

  const validateSubmit = (e) => {
    e.preventDefault();
    const tempErrors = {
      newpassword: validations.password(newpassword),
      cnewpassword: validations.confirmPassword(newpassword, cnewpassword),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      // console.log(
      //   "..values",
      //   Object.values(tempErrors).filter((value) => value)
      // );
      return;
    }
    SubmitHandler();
  };

  const SubmitHandler = () => {
    formData.append("password", newpassword);
    formData.append("confirm_password", cnewpassword);
    dispatch(resetForgotPassword(token, uid, formData));
  };

  useEffect(() => {
    if (success) {
      swal({
        title: " ",
        text: "Password Reset Successfully",
        className: "successAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
      navigate("/");
    }
    if (error) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }
  }, [success, error]);

  return (
    <>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login</title>
          <meta
            name="description"
            content="Martial Nexus was created by martial artists for martial artists and combat sport athletes. We understand the passion and dedication that goes into training and competing, and we wanted to create a platform where like-minded individuals could come together to share their knowledge and experiences."
          />
          <meta name="keywords" content="testt contnet" />
          <meta name="author" content="Martial nexus" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="robots"
            content="index, nofollow"
            data-react-helmet="true"
          />
        </Helmet>

        <div className="Auth-main-div">
          <section
            className="vh-100 gradient-form"
            style={{ backgroundColor: "#42c1f4" }}
          >
            <div className="container h-100 Auth-size">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="logindiv rounded-3 text-black">
                  <form onSubmit={validateSubmit} className="loginpgae">
                    <div className="cms-logo">
                      <img className="cms-login-logo" src="/img/logo.png" />
                      <h2 className="titlewelcome">Reset your password</h2>
                    </div>

                    <div
                      className={
                        errors.newpassword
                          ? "inputCntnr inputCntnr2 error"
                          : "inputCntnr inputCntnr2"
                      }
                    >
                      <div className="form-outline mb-2">
                        <label className="form-label" for="current-password">
                          New Password:
                        </label>
                        <input
                          id="current-password"
                          className="form-control"
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => {
                            setnewpassword(e.target.value);
                            setErrors({ ...errors, newpassword: null });
                          }}
                        />
                        <span
                          style={{
                            color: "#D14F4F",
                            float: "right",
                            fontSize: "13px",
                            opacity: errors.newpassword ? 1 : 0,
                          }}
                        >
                          {errors.newpassword ?? "valid"}
                        </span>
                      </div>
                      <div className="show-hide-password">
                        <button onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <>
                              <i class="far fa-eye"></i>
                            </>
                          ) : (
                            <>
                              <i class="fas fa-eye-slash"></i>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                    <div
                      className={
                        errors.cnewpassword
                          ? "inputCntnr inputCntnr2 error"
                          : "inputCntnr inputCntnr2"
                      }
                    >
                      <div className="form-outline mb-2">
                        <label className="form-label" for="current-password">
                          Confirm Password:
                        </label>
                        <input
                          type="password"
                          id="current-password"
                          className="form-control"
                          onChange={(e) => {
                            setCnewpassword(e.target.value);
                            setErrors({ ...errors, cnewpassword: null });
                          }}
                        />
                        <span
                          style={{
                            color: "#D14F4F",
                            float: "right",
                            fontSize: "13px",
                            opacity: errors.cnewpassword ? 1 : 0,
                          }}
                        >
                          {errors.cnewpassword ?? "valid"}
                        </span>
                      </div>
                    </div>
                    <div className="pt-1 mb-3 mt-3 pb-1 text-center">
                      <button
                        onSubmit={validateSubmit}
                        className="Auth-button"
                        type="submit"
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </>
  );
};

export default Reset_password;
