import html2canvas from "html2canvas";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef, createRef } from "react";
import swal from "sweetalert";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
} from "recharts";
import { createScale, Zoom } from 'recharts-scale';
import Dropzone from "react-dropzone";
import {
  newTestFile,
  getTestFiles,
  saveNewFile,
} from "../Redux/Action/newfile-action";
import zIndex from "@material-ui/core/styles/zIndex";

const Newfile = (props) => {
  const domRef = useRef();
  const [parameter, setParameter] = useState(0);
  const [falsePositiveRate, setFalsePositiveRate] = useState("");
  const [res, setRes] = useState(0);
  const [projectData, setProjectData] = useState({
    Tau: 0,
    intercept: 0,
    p: 0,
    s: 0,
    slope: 0,
    var_s: 0,
    z: 0,
  });
  const [histogramData, setHistogramData] = useState({
    detrended_data: 0,
    mean: 0,
    histogram_bin: 0,
    std_dev: 0,

  });
  const [file, setFile] = useState(null);
  const [rerender, setRerender] = useState(false);
  const [errors, setErrors] = useState({
    file: null,
  });
  const dispatch = useDispatch();

  const { getNewFiles, success } = useSelector(
    (state) => state.getTextFileReducer
  );
  const {
    success: successAdd,
    addfiledata,
    message: addmessage,
    error: errorOfFile,
  } = useSelector((state) => state.newTextFileReducer);

  const {
    success: saveSuccess,

    message: saveMessage,
    error: errorOfSaveFile,
    saveFileData,
  } = useSelector((state) => state.saveNewFileReducer);



  useEffect(() => {
    if (addfiledata) {
      if (props.selectedMethod === "1") {
        setProjectData(addfiledata?.hamed_rao_modification_test);
        setHistogramData(addfiledata?.Histogram_plot);
        localStorage.setItem(
          "response_data",
          JSON.stringify(addfiledata?.clicks?.num_of_click)
        );
      }
      if (props.selectedMethod === "2") {
        setProjectData(addfiledata?.yue_wang_modification_test);
        setHistogramData(addfiledata?.Histogram_plot);

        localStorage.setItem(
          "response_data",
          JSON.stringify(addfiledata?.clicks?.num_of_click)
        );
      }
    }
  }, [successAdd, props.selectedMethod]);

  useEffect(() => {
    if (saveFileData) {
      if (props.selectedMethod === "1") {
        setProjectData(saveFileData?.hamed_rao_modification_test);
        setHistogramData(saveFileData?.Histogram_plot);

      }
      if (props.selectedMethod === "2") {
        setProjectData(saveFileData?.yue_wang_modification_test);
        setHistogramData(saveFileData?.Histogram_plot);

      }
    }
  }, [saveSuccess, props.selectedMethod]);

  const handleDownload = () => {
    html2canvas(domRef.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "image.png";
      downloadLink.click();
    });
  };

  const data = [
    { x: "Tau", y: projectData.Tau },
    { x: "intercept", y: projectData.intercept },
    { x: "p", y: projectData.p },
    { x: "s", y: projectData.s },
    { x: "slope", y: projectData.slope },
    { x: "var_s", y: projectData.var_s },
    { x: "z", y: projectData.z },
  ];
  const [domain, setDomain] = useState(null);

  const handleZoom = (newDomain) => {
    setDomain(newDomain);
  };

  const histogram = [
    { x: "detrended_data", y: histogramData?.detrended_data },
    { x: "mean", y: histogramData?.mean },
    { x: "histogram_bin", y: histogramData?.hist_bin },
    { x: "std_dev", y: histogramData?.std_dev },

  ];

  const onDrop = (acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    const fileType = acceptedFile.type;
    const validFileType = fileType === "text/plain";

    if (validFileType) {
      setFile(acceptedFile);
      setErrors({ ...errors, file: null });
    } else {
      setErrors({ ...errors, file: "File must be in .txt format" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("txt_file", file);

    setRerender(true);
    dispatch(newTestFile(formData));
  };

  const handleSaveSubmit = (e) => {
    e.preventDefault();

    const formData2 = new FormData();
    formData2.append("txt_file", file);

    dispatch(saveNewFile(formData2));
  };

  useEffect(() => {
    dispatch(getTestFiles());
  }, [successAdd]);

  function handleMethodChange(event) {
    props.setSelectedMethod(event.target.value);
  }

  // confidence

  const handlePlusClick = () => {
    setParameter(parameter + 1);
    computeFalsePositiveRate();
  };

  const handleMinusClick = () => {
    setParameter(parameter - 1);
    computeFalsePositiveRate();
  };

  const handleInputChange = (event) => {
    setParameter(parseInt(event.target.value));
    computeFalsePositiveRate();
  };

  const computeFalsePositiveRate = () => {
    let rate = "";
    if (parameter > 0) {
      if (parameter === 1 && confidenceLevel === "95%") {
        rate = "1 in 20 reported trends are false";
      } else if (confidenceLevel === "90%") {
        rate = "1 in 10 reported trends are false";
      } else if (confidenceLevel === "99%") {
        rate = "1 in 100 reported trends are false";
      }
    }
    setFalsePositiveRate(rate);
  };

  const handleSelectChange = (event) => {
    setConfidenceLevel(event.target.value);
    computeFalsePositiveRate();
  };

  const options = [
    { value: "95%", label: "95%" },
    { value: "90%", label: "90%" },
    { value: "99%", label: "99%" },
  ];
  const [confidenceLevel, setConfidenceLevel] = useState(options[0].value);

  const [yAxisLabel, setYAxisLabel] = useState('Y Axis Label');

  const handleLabelChange = (event) => {
    setYAxisLabel(event.target.value);
  };
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPopupVisible(true);
    setPopupPosition({ x: event.pageX, y: event.pageY });
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
  };

  // line  width


  const [width, setWidth] = useState(1);

  const handleSliderChange = (event) => {
    setWidth(event.target.value);
  };

  return (
    <>
      <div className="dashboardDiv block">
        <div >
          {popupVisible && (
            <div
              style={{
                position: 'absolute',
                left: popupPosition.x,
                top: popupPosition.y,
                backgroundColor: 'white',
                border: '1px solid black',
                padding: '10px',

              }}
            >
              <input type="text" onChange={handleLabelChange} value={yAxisLabel} />
              <button onClick={handlePopupClose}>Close</button>
            </div>
          )}

        </div>
        <div className="divGraph">
          <div className="chartDiv" ref={domRef}  >
            <input type="range" min="1" max="6" value={width} onChange={handleSliderChange} />
            <div onContextMenu={handleContextMenu}>
              <LineChart width={500} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="x" />
                <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="y" stroke="#7867C1" strokeWidth={width} />
                <Brush stroke="#7867C1" strokeWidth={1} fill="#42c1f4" height={15} />

                {/* <Line
              type="linear"
              data={predictedData}
              dataKey="y"
              stroke="green"
              name="Trendline" 
            /> */}
              </LineChart>
            </div>
            <LineChart width={500} height={300} data={histogram}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="x" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Line type="monotone" dataKey="y" stroke="#7867C1" strokeWidth={width} />
              <Brush stroke="#7867C1" strokeWidth={1} fill="#42c1f4" height={15} />

            </LineChart>


            <ResponsiveContainer width={500} height={400} >
            <ScatterChart
             data={data} 
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid />
              <XAxis type="number" dataKey="x" name="stature" />
              <YAxis type="number" dataKey="y" name="weight" />
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              <Scatter name="A school"  dataKey="y" fill="#8884d8" />
              {/* <Line
                type="linear"
                data={data}
                dataKey="y"
                stroke="green"
                name="Trendline"
              /> */}
            </ScatterChart>
          </ResponsiveContainer>


          </div>
          <button className="del btnSS" onClick={(e) => handleDownload(e)}>
            Download
          </button>
        </div>
      </div>
      <div className="fileBlock">
        <form className="newfileposition">
          <div>
            {file?.name ? <p className="fileName"> {file?.name}</p> : null}
            <div className="dashboardDiv file">
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <label
                      htmlFor="file-input-area"
                      className="button FileInput"
                    >
                      Add File
                    </label>
                    <p className="errorMsg">{errors.file || null}</p>
                    <p className="errorMsg">{errorOfFile || errorOfSaveFile}</p>
                  </div>
                )}
              </Dropzone>
              <div className="methods">
                <select
                  className="method-select"
                  value={props.selectedMethod}
                  onChange={handleMethodChange}
                  required
                >
                  <option value="1">Hamed Rao Test</option>
                  <option value="1">Hamed Rao v2</option>
                  <option value="1">Hamed Rao v3</option>
                  <option value="1">Hamed Rao v4</option>
                  <option value="2">Yue Wang Test</option>
                  <option value="2">Yue Wang v2</option>
                  <option value="2">Yue Wang v3</option>
                  <option value="2">Yue Wang v4</option>
                </select>
              </div>

              <div className="confiSec">
                <button
                  type="button"
                  className="del add"
                  onClick={handleMinusClick}
                >
                  -
                </button>
                <input
                  className="confiInput"
                  type="number"
                  value={parameter}
                  onChange={handleInputChange}
                />
                <button type="button" className="del add" onClick={handlePlusClick}>
                  +
                </button>
                <select
                  value={confidenceLevel}
                  className="method-select confi"
                  onChange={handleSelectChange}
                >
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="confimsg" style={{ fontSize: "12px" }}>
                  {falsePositiveRate}
                </div>
              </div>
              <div className="All buttons">
                <button type="button" className="del" onClick={handleSubmit}>
                  Save To Server
                </button>

                <button
                  type="button"
                  className="del"
                  onClick={(e) => handleSaveSubmit(e)}
                >
                  Save Temporary
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Newfile;
