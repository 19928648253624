import axios from "axios";
import { BACKEND_URL } from "../../environment";
import api from "../../utils/api";

import {
  NEW_TEXTFILE_REQUEST,
  NEW_TEXTFILE_SUCCESS,
  NEW_TEXTFILE_FAIL,
  PERPLEXITY_AI_REQUEST,
  PERPLEXITY_AI_SUCCESS,
  PERPLEXITY_AI_FAIL,
  SAVENEWFILE_REQUEST,
  SAVENEWFILE_SUCCESS,
  SAVENEWFILE_FAIL,
  GET_TEXTFILES_REQUEST,
  GET_TEXTFILES_SUCCESS,
  GET_TEXTFILES_FAIL,
  DELETE_TEXTFILES_REQUEST,
  DELETE_TEXTFILES_SUCCESS,
  DELETE_TEXTFILES_FAIL,
 POST_TRACKING_ACTION_REQUEST,
 POST_TRACKING_ACTION_SUCCESS,
 POST_TRACKING_ACTION_FAIL,

 POST_PERMISSIONS_REQUEST,
 POST_PERMISSIONS_SUCCESS,
 POST_PERMISSIONS_FAIL,
} from "../Constants/newfile-constant";

export const newTestFile = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_TEXTFILE_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_URL}txt-file/`,
      params,
      config
    );

    const updated_number_of_clicks = data?.clicks?.num_of_click;

    if (userData?.num_insert_queries && userData?.num_insert_queries !== 0) {
      const updatedUserData = { ...userData };

      // Update the 'num_insert_queries' property
      updatedUserData["num_insert_queries"] = updated_number_of_clicks;

      localStorage.setItem("userData", JSON.stringify(updatedUserData));
    }

    dispatch({
      type: NEW_TEXTFILE_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: NEW_TEXTFILE_FAIL,
      payload: error?.response?.data?.txt_file[0]
        ? error?.response?.data?.txt_file[0]
        : error?.response?.message,
    });
  }
};

export const perplexityAiAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PERPLEXITY_AI_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_URL}preplexity_ai/`,
      params,
      config
    );

    dispatch({
      type: PERPLEXITY_AI_SUCCESS,
      payload: data?.response,
    });

    return true;
  } catch (error) {
    dispatch({
      type: PERPLEXITY_AI_FAIL,
      payload: error?.response?.data?.txt_file[0]
        ? error?.response?.data?.txt_file[0]
        : error?.response?.message,
    });
  }
};


export const saveNewFile = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SAVENEWFILE_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_URL}upload_testfile/`,
      params,
      config
    );

    dispatch({
      type: SAVENEWFILE_SUCCESS,
      payload: data,
    });
    console.log("data", data);

    return true;
  } catch (error) {
    dispatch({
      type: SAVENEWFILE_FAIL,
      payload: error?.response?.data?.txt_file[0]
        ? error?.response?.data?.txt_file[0]
        : error?.response?.message,
    });
  }
};

export const getTestFiles = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEXTFILES_REQUEST,
    });
    const { data } = await axios.get(`${BACKEND_URL}txt-file/`);

    dispatch({
      type: GET_TEXTFILES_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: GET_TEXTFILES_FAIL,
      payload:
        error?.response && error.response.data.message?.email?.[0]
          ? error.response && error.response.data.message?.email?.[0]
          : error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteTestFiles = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TEXTFILES_REQUEST,
    });
    const { data } = await axios.delete(`${BACKEND_URL}txt-file/${id}`);

    dispatch({
      type: DELETE_TEXTFILES_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: DELETE_TEXTFILES_FAIL,
      payload:
        error?.response && error.response.data.message?.email?.[0]
          ? error.response && error.response.data.message?.email?.[0]
          : error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};



export const posttrackingaction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POST_TRACKING_ACTION_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_URL}tracker/`,
      params,
      config
    );

    dispatch({
      type: POST_TRACKING_ACTION_SUCCESS,
      payload: data,
    });
    console.log("data", data);

    return true;
  } catch (error) {
    dispatch({
      type: POST_TRACKING_ACTION_FAIL,
      payload: error?.response?.data?.txt_file[0]
        ? error?.response?.data?.txt_file[0]
        : error?.response?.message,
    });
  }
};

export const postPermissionsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POST_PERMISSIONS_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.put(
      `${BACKEND_URL}Track-permission/`,
      params,
      config
    );

    dispatch({
      type: POST_PERMISSIONS_SUCCESS,
      payload: data,
    });
    console.log("data", data);

    return true;
  } catch (error) {
    dispatch({
      type: POST_PERMISSIONS_FAIL,
      payload: error?.response?.data?.txt_file[0]
        ? error?.response?.data?.txt_file[0]
        : error?.response?.message,
    });
  }
};