import React from 'react'

function Error() {
  return (
    <>
     <div className="container">
        <div  className="page-not-found">
          <div  className="error-image">
            <img className="error-page" src="/img/logo.png" alt="" />
            <p id='notfound'>Page not found</p>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default Error