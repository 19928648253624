import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// import jwtDecode from "jwt-decode";

export default function ProtectedRoute({ children }) {
  const { userData } = useSelector((state) => state.authReducer);

  return userData ? children : <Navigate to="/login" />;

  // const { userData } = useSelector((state) => state.authReducer);

  // const tokenData = userData?.member;

  // const decodedToken = jwtDecode(tokenData);

  // const membershipid = decodedToken?.membership_ids;

  // if (!userData) return <Navigate to='/'/>

  // return membershipid?.length > 0 ? children : <Navigate to="/subscriptions" />;
}
