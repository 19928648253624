import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../Redux/Action/authentication-action";
import swal from "sweetalert";
import "./auth.css";
import { validations } from "../utils";
import "../App.css";
import jwtDecode from "jwt-decode";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, userData, token } = useSelector((state) => state.authReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rerender, setRerender] = useState(false);
  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });

  // const tokenData = userData?.token;

  // try {
  //   const decodedToken = jwtDecode(tokenData);
  //   console.log(decodedToken);
  //   const membershipid = decodedToken?.membership_ids;
  //   console.log(membershipid);
  // } catch (error) {
  //   console.log(error);
  // }
  const validateSubmit = (e) => {
    e.preventDefault();
    const tempErrors = {
      email: validations.Email(email),
      password: validations.password(password),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      // console.log(
      //   "..values",
      //   Object.values(tempErrors).filter((value) => value)
      // );
      return;
    }
    SubmitHandler();
  };

  const SubmitHandler = () => {
    setRerender(true);

    const formData = new FormData();

    formData.append("email", email);
    formData.append("password", password);
    dispatch(login(formData));
  };

  useEffect(() => {
    if (userData && rerender) {
      swal({
        title: "",
        text: "Login successful",
        className: "successAlert",
        icon: "/img/Colored icon.png",

        buttons: false,
        timer: 5000,
        open: true,
      });
      const tokenData = userData?.member;
      try {
        const decodedToken = jwtDecode(tokenData);

        const membershipid = decodedToken?.membership_ids;
        // console.log("decodedToken", decodedToken);
        if (membershipid > 0) {
          navigate("/dashboard");
        } else {
          navigate("/subscriptions");
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (error && rerender) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }
  }, [dispatch, userData, error]);

  return (
    <>
      <div className="Auth-main-div">
        <section
          className="vh-100 gradient-form"
          style={{ backgroundColor: "#42c1f4" }}
        >
          <div className="container h-100 Auth-size">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="logindiv rounded-3 text-black">
                <form onSubmit={validateSubmit} className="loginpgae">
                  <div className="cms-logo">
                    <img className="cms-login-logo" src="/img/logo.png" />
                    <h2 className="titlewelcome">Welcome to Geothara</h2>
                  </div>
                  <p className="text-login">
                    {" "}
                    Log into your account by entering your registered email and
                    password{" "}
                  </p>
                  <div
                    className={errors.email ? "inputCntnr error" : "inputCntnr"}
                  >
                    <div className="form-outline mb-2">
                      <label className="form-label" htmlFor="email">
                        Email:
                      </label>
                      <input
                        type="text"
                        id="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => {
                          setErrors({ ...errors, email: null });
                          setEmail(e.target.value);
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          float: "right",
                          fontSize: "13px",
                          opacity: errors.email ? 1 : 0,
                        }}
                      >
                        {errors.email ?? "valid"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      errors.password
                        ? "inputCntnr inputCntnr2 error"
                        : "inputCntnr inputCntnr2"
                    }
                  >
                    <div className="form-outline mb-2">
                      <label className="form-label" htmlFor="current-password">
                        Password:
                      </label>
                      <input
                        type="password"
                        id="current-password"
                        className="form-control"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setErrors({ ...errors, password: null });
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          float: "right",
                          fontSize: "13px",
                          opacity: errors.password ? 1 : 0,
                        }}
                      >
                        {errors.password ?? "valid"}
                      </span>
                    </div>
                  </div>
                  <div className="pt-1 mb-3 mt-3 pb-1 text-center">
                    <button
                      onSubmit={validateSubmit}
                      className="Auth-button"
                      type="submit"
                      style={{ cursor: "pointer" }}
                    >
                      Log in
                    </button>

                    <div className="register">
                      <Link to="/register" className="hover-primary" href="">
                        Signup now
                      </Link>
                    </div>
                    <div className="Forgotsec">
                      <Link className="hover-primary" to="/forgot">
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
