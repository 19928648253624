import axios from "axios";
import { BACKEND_URL } from "../../environment";
import api from "../../utils/api";

import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
} from "../Constants/adminconstants";

export const userListAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.get(
      `${BACKEND_URL}adminPanel`,
      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: error?.response?.data[0],
    });
  }
};


export const Userstatusupdate = (params,id) => async (dispatch,getState) => {
    try {
      dispatch({
        type:USER_UPDATE_REQUEST,
      });
  
      const {
        authReducer: { userData },
      } = getState();


      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      };


      const { data } = await axios.patch(
        `${BACKEND_URL}adminPanel/${id}/`,
        params,
        config
      );
  
      dispatch({
        type:  USER_UPDATE_SUCCESS,
        payload: data,
      });
  
      return true;
    } catch (error) {
      dispatch({
        type:   USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
