import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Frontend_URL } from "../environment";

const PublicDashboard = () => {
  // const videoRef = useRef(null);
  const [scrollClass, setScrollClass] = useState("");

  const handleClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // useEffect(() => {
  //   videoRef.current.play();
  // }, []);

  const slideImages = [
    {
      url: "/img/Artboard-1.jpg",
      caption: "2",
    },
    {
      url: "/img/Artboard-2.jpg",
      caption: "3",
    },

    {
      url: "/img/Artboard-3.jpg",
      caption: "4",
    },
    {
      url: "/img/Artboard-4.jpg",
      caption: "5",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollClass("scrolled");
      } else {
        setScrollClass("");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    autoplay: true,
    dots: true,
    showDots: true,
    showNavs: true,
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (event.target.closest(".loginRight")) return;
  //     // setShowDropdown(false);
  //   };
  //   window.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     window.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div id="home_homepage" className="topdiv">
        <div className="container-text">
          <div className="togglediv">
            <button className="togglebtn" onClick={toggleVisibility}>
              <i className="fa fa-bars"></i>
            </button>
            <div className="logo-m">
              <Link to="/">
                <img src="/img/newlogo.png" />
              </Link>
            </div>
          </div>

          <p className="emailtext">
            {" "}
            <a href="mailto: contact@geothara.com">
              <i className="fa fa-envelope"></i> contact@geothara.com
            </a>{" "}
          </p>
        </div>

        <div className={`my-element ${scrollClass}`}>
          <div>
            <div className="logonavbar">
              <div className="container">
                <div className="newlogomenu">
                  <div className="newlogo">
                    <Link to="/">
                      <img src="/img/newlogo.png" />
                    </Link>
                  </div>
                  <div className="logomenu">
                    <li>
                      <Link onClick={() => handleClick("home_homepage")} to="">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleClick("about_homepage")} to="">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleClick("our_services_homepage")}
                        to=""
                      >
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleClick("contacts_homepage")}
                        to=""
                      >
                        Contacts
                      </Link>
                    </li>
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobl">
          {isVisible && (
            <div>
              <div className="logonavbar1">
                <div className="container">
                  <div className="newlogomenu">
                    <div className="newlogo">
                      <Link to="/">
                        <img src="/img/newlogo.png" />
                      </Link>
                    </div>
                    <div className="logomenu">
                      <li>
                        <Link
                          onClick={() => handleClick("home_homepage")}
                          to=""
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => handleClick("about_homepage")}
                          to=""
                        >
                          About
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => handleClick("our_services_homepage")}
                          to=""
                        >
                          Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => handleClick("contacts_homepage")}
                          to=""
                        >
                          Contacts
                        </Link>
                      </li>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="videosec">
          <img src="/img/geothara-banner.png" />
          {/* <video ref={videoRef} autoplay loop muted>
            <source src="img/geothara_video.mp4" type="video/mp4" />
          </video> */}
        </div>
        <div className="tobtext">
          <div className="container">
            <h1>
              Data Science for Water, Energy <br />
              and Climate Security
            </h1>
            {/* <h1>Data Science for Water Security</h1> */}
          </div>
        </div>
      </div>

      <div id="about_homepage" className="main-geoThara-div">
        <section>
          <div className="contnet-div">
            <div className="container Services-top">
              <div className="grid sm:grid-cols-1  lg:grid-cols-2 gap-4">
                {/* <div className="flex gap-4"> */}

                <div className="trenddetection">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-element elementor-element-ad4f4d2 elementor-widget elementor-widget-text-editor">
                      <div className="elementor-widget-container"></div>
                    </div>
                    <div className="elementor-element elementor-element-358ec59 elementor-widget elementor-widget-text-editor top-text-1">
                      <div className="elementor-widget-container">
                        Trend Detection for a
                      </div>

                      <div className="changing-content">Changing World</div>
                    </div>
                    <div className="elementor-element elementor-element-ed42ba9 elementor-widget elementor-widget-text-editor">
                      <div className="climate-contnet">
                        <p className="my-4 test-1">
                          <b>Climate, water, agriculture, power grids,</b>
                          <span className="all-common">
                            {" "}
                            all have one thing in common: they all speak to us
                            through changing data trends.
                          </span>
                        </p>
                        <p className="my-4 test-1">
                          <b>At Geothara</b>,{" "}
                          <span className="all-common">
                            we bring together satellite data, time series,
                            decision making support, and our trend detection
                            algorithm to give you software that helps you
                            understand and visualize change.
                          </span>
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="trenddetection-vedio">
                  <Slide {...settings}>
                    {slideImages.map((slideImage, index) => (
                      <div className="each-slide" key={index}>
                        <div className="slider-image-div">
                          <img src={slideImage.url} className="w-100 " />
                        </div>
                      </div>
                    ))}
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        id="our_services_homepage"
        className="Services"
        style={{ background: `url(${Frontend_URL}/img/Group-1141.jpg)` }}
      >
        <div className="container Services-top">
          <h1 className="Servicestitle">Our Services</h1>
          <div className="allServices">
            <div className="Services1">
              <h2 className="nuber">1</h2>
              <img className="Services-icon-img" src="/img/Services1.webp" />
              <h3 className="datatext">Data</h3>
              <p className="Services-test">
                A wide range of geographic data from satellites and other
                sources{" "}
              </p>
            </div>
            <div className="Services-icon">
              <img src="/img/Vector-1.webp" />
            </div>
            <div className="Services1">
              <h2 className="nuber">2</h2>
              <img className="Services-icon-img" src="/img/Services2.webp" />
              <h3 className="datatext">Algorithms</h3>
              <p className="Services-test">
                Trend detection, digital signal processing, hydro-meteorology,
                climate science
              </p>
            </div>
            <div className="Services-icon">
              <img src="/img/Vector-1.webp" />
            </div>
            <div className="Services1">
              <h2 className="nuber">3</h2>
              <img className="Services-icon-img" src="/img/Services3.webp" />
              <h3 className="datatext">Mapping</h3>
              <p className="Services-test">
                We offer engaging visuals to communicate complex change in GIF
                and video formats
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="contacts_homepage" className="Contactsec">
        <div className="container Services-top">
          <div className="Contactsecdiv">
            <div className="Contactsecdiv-text">
              <div className="con-text">
                <h1 className="Contacttitle">Contact Us</h1>
                <h2 className="contactlink">
                  <a href="mailto: contact@geothara.com">
                    contact@geothara.com
                  </a>
                </h2>
              </div>
            </div>
            <div className="Contactsecdiv-img">
              <img src="/img/contact.webp" />
            </div>
          </div>
        </div>
      </div>

      <div className="bottomsec">
        <div className="container Services-top">
          <div className="footer">
            <div className="footer-logo">
              <Link to="/">
                <img src="/img/newlogo.png" />
              </Link>
            </div>
            <div className="footer-text">
              <p>contact@geothara.com</p>
              <p>11 E Loop Road, Ste 381, New York, NY 10044</p>
              <p>© 2022, Geothara. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicDashboard;
