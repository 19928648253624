import React from "react";

function Footer() {
  return (
    <>
      <div className="public-footer ">
        <div className="container">
          <p>Geothara</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
