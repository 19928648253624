import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import {
  newTestFile,
  getTestFiles,
  saveNewFile,
} from "../Redux/Action/newfile-action";
import { useNavigate } from "react-router-dom";

const AddNewFile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // addfile
  const [file, setFile] = useState(null);
  const [rerender, setRerender] = useState(false);
  const [errors, setErrors] = useState({
    file: null,
  });
  const onDrop = (acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    const fileType = acceptedFile.type;
    const validFileType = fileType === "text/plain";

    if (validFileType) {
      setFile(acceptedFile);
      setErrors({ ...errors, file: null });
    } else {
      setErrors({ ...errors, file: "File must be in .txt format" });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("txt_file", file);

    setRerender(true);
    dispatch(newTestFile(formData));
    navigate("/result-page");
  };
  //   useEffect(() => {
  //     if (addfiledata) {
  //       if (props.selectedMethod === "1") {
  //         setProjectData(addfiledata?.hamed_rao_modification_test);
  //         setHistogramData(addfiledata?.Histogram_plot);
  //         localStorage.setItem(
  //           "response_data",
  //           JSON.stringify(addfiledata?.clicks?.num_of_click)
  //         );
  //       }
  //       if (props.selectedMethod === "2") {
  //         setProjectData(addfiledata?.yue_wang_modification_test);
  //         setHistogramData(addfiledata?.Histogram_plot);

  //         localStorage.setItem(
  //           "response_data",
  //           JSON.stringify(addfiledata?.clicks?.num_of_click)
  //         );
  //       }
  //     }
  //   }, [successAdd, props.selectedMethod]);

  //   useEffect(() => {
  //     if (saveFileData) {
  //       if (props.selectedMethod === "1") {
  //         setProjectData(saveFileData?.hamed_rao_modification_test);
  //         setHistogramData(saveFileData?.Histogram_plot);

  //       }
  //       if (props.selectedMethod === "2") {
  //         setProjectData(saveFileData?.yue_wang_modification_test);
  //         setHistogramData(saveFileData?.Histogram_plot);

  //       }
  //     }
  //   }, [saveSuccess, props.selectedMethod]);

  const {
    success: successAdd,
    addfiledata,
    message: addmessage,
    error: errorOfFile,
  } = useSelector((state) => state.newTextFileReducer);

  const {
    success: saveSuccess,

    message: saveMessage,
    error: errorOfSaveFile,
    saveFileData,
  } = useSelector((state) => state.saveNewFileReducer);
  const trackClick = (event) => {
    // Send data to server to track the click event
    console.log('Clicked:', event);
  };
  return (
    <div className="containeraddfile" onClick={trackClick}>
      <h2 className="dataheading">Import Data</h2>
      <div className="leftsideimport">
        <div className="addfiledata">
          <div className="oneinputtitle">
            <p className="dataTitle" style={{ color: "gray" }}>
              Select import file
            </p>
            <p className="confidenceContainer">
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <label htmlFor="file-input-area" className=" fileupload">
                      Upload
                    </label>
                    <p className="errorMsg">{errors.file || null}</p>
                    <p className="errorMsg">{errorOfFile || errorOfSaveFile}</p>
                  </div>
                )}
              </Dropzone>
              <p className="dataText">precipitation2021may.txt</p>
            </p>
          </div>

          <div className="oneinputtitle">
            {" "}
            <p className="dataTitle" style={{ color: "gray" }}>
              Select method
            </p>
            <div className="methods">
              <select
                className="method-select"
                // value={props.selectedMethod}
                // onChange={handleMethodChange}
                required
              >
                <option value="1">Hamed Rao Test</option>
                <option value="1">Hamed Rao v2</option>
                <option value="1">Hamed Rao v3</option>
                <option value="1">Hamed Rao v4</option>
                <option value="2">Yue Wang Test</option>
                <option value="2">Yue Wang v2</option>
                <option value="2">Yue Wang v3</option>
                <option value="2">Yue Wang v4</option>
              </select>
            </div>
          </div>
          <div className="oneinputtitle">
            {" "}
            <p className="dataTitle" style={{ color: "gray" }}>
              Pre-processing
            </p>
            <div className="toggledec">
              <p className="dataToggle">Prewhitening</p>
              <label class="switch ">
                <input type="checkbox" checked />
                <span class="slider round"></span>
              </label>
            </div>
            <div className="toggledec">
              <p className="dataToggle">Bootstrap</p>
              <label class="switch ">
                <input type="checkbox" checked />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div className="oneinputtitle">
            <p className="dataTitle" style={{ color: "gray" }}>
              Confidence
            </p>
            <div className="confiSec">
              <button type="button" className="del add">
                -
              </button>
              <input
                className="confiInput"
                type="number"
                placeholder="Medium"
              />
              <button type="button" className="del add">
                +
              </button>
            </div>
          </div>
        </div>
        <div className="spaceonright">
          <button className="submitbtn" type="button" onClick={handleSubmit}>
            Run
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewFile;
