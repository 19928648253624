import React, { useEffect, useState } from "react";
import FileGraphPage from "./FileGraphPage";
import DataTrendPage from "./DataTrendPage";
import MappingPage from "./MappingPage";
import VideoPage from "./VideoPage";
import { useSelector, useDispatch } from "react-redux";
import { newTestFile,posttrackingaction } from "../../Redux/Action/newfile-action";
import swal from "sweetalert";

function MainTrendPage() {
  const dispatch = useDispatch();

  const [myVar, setMyVar] = useState("Data");
  const [selectedMethod, setSelectedMethod] = useState("Hamed Rao Test");
  const [count, setCount] = useState(90);
  const [confidenceText, setConfidenceText] = useState("");
  const [clicks, setClick] = useState(null);
  const [overallclick, setoverallclick] = useState(null);

  const [showConfidenceText, setShowConfidenceText] = useState(false);
  const [runSuccessful, setRunSuccessful] = useState(false);
  const [file, setFile] = useState(null);
  const [preWhitening, setPreWhitening] = useState(true);
  const [bootstrap, setBootstrap] = useState(true);
  const [rerender, setRerender] = useState(false);

  const [errors, setErrors] = useState({
    file: null,
  });

  const {
    success: successAdd,
    addfiledata,
    message: addmessage,
    error: errorOfFile,
  } = useSelector((state) => state.newTextFileReducer);

  const onDrop = (acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    const fileType = acceptedFile.type;
    const validFileType = fileType === "text/plain" || fileType === "text/csv";

    if (validFileType) {
      setFile(acceptedFile);
      setErrors({ ...errors, file: null });
    } else {
      setErrors({ ...errors, file: "File must be in .txt format" });
    }
  };

  function incrementCount() {
    if (count === 90) {
      setCount(95);
      setConfidenceText("1 in 20 reported trends are false");
    } else if (count === 95) {
      setCount(99);
      setConfidenceText("1 in 100 reported trends are false");
    }
    setShowConfidenceText(true);
  }

  function decrementCount() {
    if (count === 95) {
      setCount(90);
      setConfidenceText("1 in 10 reported trends are false");
    } else if (count === 99) {
      setCount(95);
      setConfidenceText("1 in 20 reported trends are false");
    }
    setShowConfidenceText(true);
  }

  const validateSubmit = (e) => {
    e.preventDefault();

    const tempErrors = {
      file: !file && "Please select a file",
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }

    handleSubmit();
    setFile(null);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("txt_file", file);
    formData.append("confidence", count);

    setRerender(true);

    setRunSuccessful(true);
    dispatch(newTestFile(formData));
    // localStorage.setItem('datascreen', JSON.stringify({ "datapage": { clicks, selectedMethod, preWhitening, bootstrap, count } }));

    const existingData = JSON.parse(localStorage.getItem('datascreen')) || { datapage: [] };
    const newDatapage = { clicks, selectedMethod, preWhitening, bootstrap, confidence:count };
    existingData.datapage.push(newDatapage);

    // Store the updated data back into local storage
    localStorage.setItem('datascreen', JSON.stringify(existingData));

  };

  useEffect(() => {
    if (successAdd && rerender) {
      setMyVar("Analysis");
      setClick(null)
    }
    if (errorOfFile && rerender) {
      swal({
        title: "",
        text: errorOfFile,
        className: "successAlert",
        icon: "/smallMN.png",
        buttons: false,
        timer: 3000,
      });
    }
  }, [successAdd, errorOfFile]);

  const trackClick = (e) => {
    e.preventDefault();
    setClick(clicks + 1)
  };
const overallClick = ()=>{
  setoverallclick(overallclick + 1)
  localStorage.setItem('overallclick', JSON.stringify(overallclick));
}



  return (
    <div className="bg-[#cdcdcd63]" onClick={overallClick}>
      <div className="newgraph-div">
        <div className="graph-newdesign11">
          <div className="graph-down">
            <button
              onClick={() => {
                setMyVar("Data");
              }}
              className={
                myVar == "Data"
                  ? "all_graph_buttonnewdiv active"
                  : "all_graph_buttonnewdiv"
              }
            >
              Data
            </button>
          </div>
          <div className="graph-down">
            <button
              onClick={() => {
                setMyVar("Analysis");
              }}
              disabled={!runSuccessful}
              className={
                myVar == "Analysis"
                  ? "all_graph_buttonnewdiv active"
                  : "all_graph_buttonnewdiv"
              }
            >
              Analysis
            </button>
          </div>{" "}
          <div className="graph-down smallscreendiv">
            <button
              onClick={() => {
                setMyVar("Mapping");
              }}
              className={
                myVar == "Mapping"
                  ? "all_graph_buttonnewdiv active"
                  : "all_graph_buttonnewdiv"
              }
            >
              Mapping
            </button>
          </div>{" "}
          <div className="graph-down smallscreendiv ">
            <button
              onClick={() => {
                setMyVar("Video");
              }}
              className={
                myVar == "Video"
                  ? "all_graph_buttonnewdiv active"
                  : "all_graph_buttonnewdiv"
              }
            >
              Video
            </button>
     
          </div>
        </div>
      </div>
      {myVar === "Data" && (
        <DataTrendPage
          addfiledata={addfiledata}
          errorOfFile={errorOfFile}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          successAdd={successAdd}
          count={count}
          setCount={setCount}
          confidenceText={confidenceText}
          setConfidenceText={setConfidenceText}
          showConfidenceText={showConfidenceText}
          setShowConfidenceText={setShowConfidenceText}
          runSuccessful={runSuccessful}
          setRunSuccessful={setRunSuccessful}
          onDrop={onDrop}
          errors={errors}
          setErrors={setErrors}
          file={file}
          setFile={setFile}
          preWhitening={preWhitening}
          setPreWhitening={setPreWhitening}
          bootstrap={bootstrap}
          setBootstrap={setBootstrap}
          incrementCount={incrementCount}
          decrementCount={decrementCount}
          validateSubmit={validateSubmit}
          trackClick={trackClick}
          clicks={clicks}
          setClick={setClick}
        />
      )}
      {myVar === "Analysis" && (
        <FileGraphPage
          addfiledata={addfiledata}
          errorOfFile={errorOfFile}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          successAdd={successAdd}
          count={count}
          setCount={setCount}
          showConfidenceText={showConfidenceText}
          setShowConfidenceText={setShowConfidenceText}
          runSuccessful={runSuccessful}
          setRunSuccessful={setRunSuccessful}
          onDrop={onDrop}
          errors={errors}
          file={file}
          preWhitening={preWhitening}
          setPreWhitening={setPreWhitening}
          bootstrap={bootstrap}
          setBootstrap={setBootstrap}
          incrementCount={incrementCount}
          decrementCount={decrementCount}
          validateSubmit={validateSubmit}
       
        />
      )}
      {myVar === "Mapping" && <MappingPage />}
      {myVar === "Video" && <VideoPage />}
    </div>
  );
}

export default MainTrendPage;
