import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { logout } from "../Redux/Action/authentication-action";
import { useDispatch, useSelector } from "react-redux";
import Home from "../Components/home";
import MyChart from "../Components/graph";
import ResultPage from "../Components/resultPage";
import AddNewFile from "../Components/addNewFile";
import Layout from "../layouts/Layout";
import Footer from "../layouts/Footer";

const Dashboard = () => {
  // const [count, setCount] = useState();
  // const { userData } = useSelector((state) => state.authReducer);

  // const { success: successAdd, addfiledata } = useSelector(
  //   (state) => state.newTextFileReducer
  // );

  // const { success: successSave, saveFileData } = useSelector(
  //   (state) => state.saveNewFileReducer
  // );

  // useEffect(() => {
  //   setCount(localStorage.getItem("response_data"));
  // }, [successAdd, successSave]);

  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const logoutHandler = () => {
  //   dispatch(logout());
  //   navigate("/");
  // };
  return (
    <>
      <div className="dashboardpage">
        {/* <div className="HomepageMain d-flex">
        <div className="cms-logo ">
          <img className="logo-dashboard  dash" src="/img/logo.png" />
        </div>
        <p className="count">
          Count Left:{count ? count : userData?.num_insert_queries}
        </p>
        <div class="center mt-2 thankyoudiv">
          {userData?.user == "admin" && (
            <Link
              className="hover-primary subs"
              to="/userlist"
              style={{ marginLeft: "5px" }}
            >
              Userlist
            </Link>
          )}
          <Link
            className="hover-primary subs"
            to="/subscriptions"
            style={{ marginLeft: "5px" }}
          >
            Subscription Pack
          </Link>
          <Link className="hover-primary" to="/password-change">
            Update password?
          </Link>
          <button className="successLogout" onClick={() => logoutHandler()}>
            Log out
          </button>
        </div> */}
        {/* </div> */}
        {/* <Layout/> */}

        <AddNewFile />
        {/* <ResultPage /> */}
        {/* <Footer/>
      <div class="homepage">
        <div class="homepagepay">
          <div class="thanktexthome">
            <p className="Subscription">Welcome to Dashboard</p>

            <Home />
            <MyChart />
          </div>
        </div>
      </div> */}
      </div>
    </>
  );
};

export default Dashboard;
