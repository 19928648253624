import React, { useState } from "react";

const InteractiveBar = () => {
  const [divPosition, setDivPosition] = useState({ top: 0 });

  const handleTextClick = (event) => {
    const textPosition = event.target.getBoundingClientRect();
    setDivPosition({ top: textPosition.top });
  };

  return (
    <div className="bar-container">
      {/* The div inside the bar */}
      <div className="inner-div" style={{ top: divPosition.top }}></div>

      {/* Text elements along the length of the bar */}
      <p onClick={handleTextClick} style={{ cursor: "pointer" }}>
        Text 1
      </p>
      <p onClick={handleTextClick} style={{ cursor: "pointer" }}>
        Text 2
      </p>
      <p onClick={handleTextClick} style={{ cursor: "pointer" }}>
        Text 3
      </p>
      <p onClick={handleTextClick} style={{ cursor: "pointer" }}>
        Text 4
      </p>
    </div>
  );
};

export default InteractiveBar;
