import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // Official Mapbox Geocoder CSS
import { BACKEND_URL } from "../../environment";
import axios from "axios";
import { useSelector } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2VvdGhhcmEiLCJhIjoiY2xrd21tb2VpMTJ0djNybXliZzhwb29hOCJ9.3hVMlLmgoVu8cOPEH5IsYg";

function MappingPage() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [selectCountry, setSelectCountry] = useState("New York City"); // Set initial value here
  const [selectDataType, setSelectDataType] = useState("admins"); // Set initial value here
  const [downloadingMapData, setDownloadingMapData] = useState(false); // Set initial value here
  const [buttonTextDownload, setButtonTextDownload] = useState("Download"); // Set initial value here

  const { userData } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (map.current) return;

    // Initialize map
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [-74.006, 40.7128], // Set the initial center for Seattle
      zoom: 12,
    });

    // Geolocation
    // ... (rest of the geolocation code)

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
      position: "top-left",
    });

    // Add controls to the map
    // ... (rest of the control code)

    // Add controls to the map
    // newMap.addControl(new mapboxgl.NavigationControl()); // Add navigation controls
    newMap.addControl(geocoder, "top-right");
    // newMap.addControl(new mapboxgl.NavigationControl(), "top-right");
    // newMap.addControl(
    //   new mapboxgl.FullscreenControl(
    //     {
    //       position: "bottom-right",
    //     },
    //     "bottom-right"
    //   )
    // );
    newMap.addControl(
      new mapboxgl.NavigationControl(
        {
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
          // position: "bottom-right",
        },
        "bottom-right"
      )
    );
    // newMap.addControl(new mapboxgl.NavigationControl());

    map.current = newMap;

    // Set the initial selected country using the handleCountryChange function
    handleCountryChange({ target: { value: selectCountry } });
  }, []);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectCountry(selectedCountry);

    // Update map center and zoom based on the selected country
    if (selectedCountry === "Seattle") {
      map.current.setCenter([-122.3321, 47.6062]);
      map.current.setZoom(12);
    } else if (selectedCountry === "Chicago") {
      map.current.setCenter([-87.6298, 41.8781]);
      map.current.setZoom(12);
    } else if (selectedCountry === "New York City") {
      map.current.setCenter([-74.006, 40.7128]);
      map.current.setZoom(12);
    } else if (selectedCountry === "Ooty") {
      map.current.setCenter([76.6932, 11.4069]);
      map.current.setZoom(12);
    } else if (selectedCountry === "Bangalore") {
      map.current.setCenter([77.5946, 12.9716]);
      map.current.setZoom(12);
    }
  };

  // function addGeoPackageLayersToMap(mapInstance) {
  //   const sourceId = "geopackage-source";

  //   // Check if the source already exists
  //   if (!mapInstance.getSource(sourceId)) {
  //     const geoPackageUrl = "/data_buildings.gpkg";
  //     mapInstance.addSource(sourceId, {
  //       type: "vector",
  //       url: geoPackageUrl,
  //     });

  //     mapInstance.addLayer({
  //       id: "geopackage-layer",
  //       type: "circle", // Use the appropriate layer type
  //       source: sourceId,
  //       "source-layer": "YOUR_SOURCE_LAYER_NAME", // Replace with the source layer name from your GeoPackage
  //       paint: {
  //         "circle-radius": 6,
  //         "circle-color": "#FF0000",
  //       },
  //     });
  //   }
  // }

  const handleDownloadingMapData = (e) => {
    e.preventDefault();

    setDownloadingMapData(true);
    setButtonTextDownload("Downloading, Please Wait...");

    if (selectCountry === "New York City") {
      // if (
      //   selectCountry === "New York City" &&
      //   buttonTextDownload !== "Download"
      // ) {
      let layerName;
      let color;
      if (selectDataType === "admins") {
        layerName = "/assets/data_admins_NYC_10000.geojson";
        color = "yellow";
      } else if (selectDataType === "buildings") {
        layerName = "/assets/data_buildings_NYC_10000.geojson";
        color = "red";
      } else if (selectDataType === "places") {
        layerName = "/assets/data_places_NYC_10000.geojson";
        color = "green";
      } else if (selectDataType === "transportation") {
        layerName = "/assets/data_transportation_NYC_10000.geojson";
        color = "blue";
      }
      // Perform the download action without axios
      const url = layerName; // Replace with your file URL
      const a = document.createElement("a");
      a.href = url;
      a.download = `${selectCountry}_${selectDataType}.geojson`; // Set the desired filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      addGeoPackageLayersToMap(
        map.current,
        `geopackage-source-${selectDataType}`,
        layerName,
        color
      );

      setButtonTextDownload("Download");

      return;
    }

    const formData = new FormData();
    formData.append("city", selectCountry);
    formData.append("dataType", selectDataType);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    axios
      .post(`${BACKEND_URL}download_mapData/`, formData, config) // Replace with your actual API endpoint
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: "application/gpkg" });

        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `data_${selectDataType}.gpkg`; // Set the desired filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        setDownloadingMapData(false);
        setButtonTextDownload("Download");
      })
      .catch((error) => {
        setDownloadingMapData(false);
        setButtonTextDownload(error?.response?.data?.message);
        setTimeout(() => {
          setButtonTextDownload("Download");
        }, 3000);
      });
  };

  function addGeoPackageLayersToMap(mapInstance, sourceId, layerName, color) {
    if (!mapInstance.getSource(sourceId)) {
      const layers = mapInstance.getStyle().layers;
      // Find the index of the first symbol layer in the map style.
      let firstSymbolId;
      for (const layer of layers) {
        if (layer.type === "symbol") {
          firstSymbolId = layer.id;
          break;
        }
      }
      mapInstance.addSource(sourceId, {
        type: "geojson",
        data: layerName,
      });
      mapInstance.addLayer(
        {
          id: sourceId,
          type: "fill",
          source: sourceId,
          layout: {},
          paint: {
            "fill-color": color,
            // "fill-color": "#f08",
            "fill-opacity": 0.4,
          },
        },
        firstSymbolId
      );
    }
  }

  return (
    <div>
      <div className="map-main-div bg-white">
        {/* {loading ? (
          <LoadingSpinner />
        ) : (
          <> */}
        <div className="select-country-maindiv">
          <select
            className="select-country-box"
            value={selectCountry}
            onChange={handleCountryChange}
          >
            <option value="New York City">New York City</option>
            <option value="Seattle">Seattle</option>
            <option value="Chicago">Chicago</option>
            <option value="Ooty">Ooty</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Los Angeles">Los Angeles</option>
            <option value="Chennai">Chennai</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Delhi">Delhi</option>
            <option value="Tokyo">Tokyo</option>
            <option value="London">London</option>
            <option value="Paris">Paris</option>
            <option value="Berlin">Berlin</option>
            <option value="Milan">Milan</option>
            <option value="Genova">Genova</option>
          </select>

          <select
            className="select-country-box"
            value={selectDataType}
            style={{ marginLeft: "10px" }}
            onChange={(e) => setSelectDataType(e.target.value)}
          >
            <option value="admins">Admins</option>
            <option value="buildings">Buildings</option>
            <option value="places">Places</option>
            <option value="transportation">Transportation</option>
          </select>
          <button
            type="button"
            style={{
              marginBottom: "5px",
              marginLeft: "10px",
              color: "black",
            }}
            disabled={buttonTextDownload !== "Download"}
            onClick={(e) => handleDownloadingMapData(e)}
          >
            {buttonTextDownload}
          </button>
        </div>
        <div className="mapbox-show" ref={mapContainer} />
      </div>
    </div>
  );
}

export default MappingPage;
