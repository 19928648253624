import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  RegisterReducer,
  LoginReducer,
  resetPasswordReducer,
  resetForgotPasswordReducer,
  forgotPasswordReducer,
  UpdateprofileReducer,
  verifyEmailReducer,
  LogoutReducer,
} from "./Redux/Reducer/authentication-reducer";
import {
  newTextFileReducer,
  perplexityAIReducer,
  saveNewFileReducer,
  getTextFileReducer,
  deleteTextFileReducer,
  posttrackingaction,
  getPermissionsReducer,
} from "./Redux/Reducer/newfile-reducer";
import {
  getUserListReducer,
  userUpdateReducer,
} from "./Redux/Reducer/adminReducer";
import {
  PaymentReducer,
  MembershipReducer,
  MembershipDetailsReducer,
} from "./Redux/Reducer/payment-reducer";
const reducer = combineReducers({
  authReducer: LoginReducer,
  RegisterReducer,
  PaymentReducer,
  resetPasswordReducer,
  resetForgotPasswordReducer,
  forgotPasswordReducer,
  UpdateprofileReducer,
  verifyEmailReducer,
  perplexityAIReducer,
  LogoutReducer,
  MembershipReducer,
  MembershipDetailsReducer,
  newTextFileReducer,
  saveNewFileReducer,
  getTextFileReducer,
  deleteTextFileReducer,
  getUserListReducer,
  userUpdateReducer,
  posttrackingaction,
  getPermissionsReducer,
});

// get userData from localStorage
const userDataFromStorage = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;

// initialState
const initialState = {
  authReducer: { userData: userDataFromStorage },
};
// middleware used thunk
const middleware = [thunk];

// store variable initialized
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
