export const firstName = (value) => {
  if (!value) return "First name is required";

  if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    return "First Name can only contain characters";

  return null;
};

export const lastName = (value) => {
  if (!value) return "Last name is required";

  if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    return "Last Name can only contain characters ";

  return null;
};

export const username = (value) => {
  if (!value) return "Username is required";

  if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    return "Username can only contain characters";

  return null;
};

export const number = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const message = (value) => {
  if (!value) return "Message is required";
  return null;
};

export const Name = (value) => {
  if (!value) return "Name is required";
  return null;
};

export const Email = (value) => {
  if (!value) return "Email is required";

  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters ";

  return null;
};

export const status = (value) => {
  if (!value) return "Status is required";
  return null;
};

export const company_name = (value) => {
  if (!value) return "Company Name is required";
  return null;
};

export const role = (value) => {
  if (!value) return "Role is required";

  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters ";

  return null;
};

export const passwordRequired = (value) => {
  if (!value) return "Password is required";

  // if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
  //   return "First Name can only contain characters ";

  return null;
};

export const email = (value) => {
  if (!value) return "Email is required";

  if (
    !value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )
    return "Email is not valid";

  return null;
};

export const price = (value) => {
  if (!value) return "Please input a price";

  if (value < 5) return "Greater than $5";

  return null;
};

export const tags = (value) => {
  if (!value) return "Please input atleast one tag";
  return null;
};

export const jobImages = (value) => {
  if (!value) return "Please select atleast one image";
  return null;
};

export const emailRequired = (value) => {
  if (!value) return "Username/Email is required";
  return null;
};

export const category = (value) => {
  if (!value) return "Category is required";
  return null;
};

export const skill_name = (value) => {
  if (!value) return "Name is required";
  return null;
};
export const stage_name = (value) => {
  if (!value) return "Name is required";
  return null;
};
export const workflow_level_name = (value) => {
  if (!value) return "Level Name is required";
  return null;
};

export const deliveryDate = (value) => {
  if (!value) return "Delivery date is required";
  return null;
};

export const title = (value) => {
  if (!value) return "Title is required";
  return null;
};

export const poem = (value) => {
  if (!value) return "poem is required";
  return null;
};

export const thought = (value) => {
  if (!value) return "Thought is required";
  return null;
};

export const socialmedia = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const description = (value) => {
  if (!value) return "Description is required";
  return null;
};

// export const phoneNumber = (value) => {
//   if (!value) return "Phone Number is required";

//   // if (!String(value).match(/"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/))
//    return "Phone Number is not valid";

//   return null;
// };

// export const phoneNumber = (mobile) => {
//   const mobileRegex = /\d{10}$/;

//   const mobileRegex1 = /^123\d{12}$/;

//   if (!mobile) {
//     return "Phone Number is required";
//   }

//   if (!mobileRegex.test(mobile) && !mobileRegex1.test(mobile)) {
//     return "Phone Number is not valid";
//   }

//   return null;
// }

export const phoneNumber = (mobile) => {
  // const mobileRegex = /^(?:\+?1)?[2-9]\d{0,12}$/;

  const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10,}$/;

  // const mobileRegex2 = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  if (!mobile) {
    return "Phone Number is required";
  }

  if (!mobileRegex.test(mobile)) {
    return "Phone Number is not valid";
  }

  return null;
};

// export const phoneNumber = (mobile) => {
//   const mobileRegex = /^123\d{8}$/;

//   if (!mobile) {
//     return "Phone Number is required";
//   }

//   if (!mobileRegex.test(mobile)) {
//     return "Phone Number is not valid";
//   }

//   return null;
// }

export const socialmediaerror = (value) => {
  if (value) return;

  if (
    !String(value).match(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,}(\/\S*)?$/
    )
  )
    return "Please enter a valid social media URL";

  return null;
};

export const password = (value) => {
  if (!value) return "Password is required";

  // if (value.length < 7) return "Atleast 7 characters required";

  return null;
};

export const confirmPassword = (password, confirmPassword) => {
  if (!password) return "Confirm Password is required";

  if (password !== confirmPassword) return "Passwords do not match";

  return null;
};

//   export const dateOfBirth = (value) => {
//     if (!value) return "Date of birth is required";
//     return null;
//   };

export const emailPassword = (value) => {
  if (!value) return "Password is required";

  if (value.length < 7) return "Atleast 7 characters required";

  return null;
};
export const currPassword = (value) => {
  if (!value) return "Password is required";

  if (value.length < 7) return "Atleast 7 characters required";

  return null;
};

export const Address = (value) => {
  if (!value) return "address is required";
  return null;
};

export const file = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const labelThepost = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const gender = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const competition_level = (value) => {
  if (!value) return "This field is required";
  return null;
};

// Events error

export const eventTitle = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const contact_title = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const eventStartDate = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const eventEndDate = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const eventTime = (value) => {
  if (!value) return "This field is required";
  return null;
};

// ***********************
export const website = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const skills = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const event_link = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const event_flyer_material = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const is_more_information_coming_soon = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const comments_by_data_entry_associate = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const online_registration_link = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const eventOrganizerFirstName = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const eventOrganizerLastName = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const eventOrganizerPhoneNumber = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const eventOrganizerEmail = (value) => {
  if (!value) return "This field is required";

  if (
    !value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )
    return "Email is not valid";

  return null;
};
export const eventOrganizerSocials = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const eventInstructions = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const relatedAsso = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const schoolName = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolAddress = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolZipCode = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolCountry = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolOwnerFName = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolOwnerLName = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const schoolOwnerPhone = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolOwnerEmail = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolOwnerSocial = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolPriceRangeMin = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolPriceRangeMax = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolOpenDays = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolOpenHours = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolIntroduction = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const schoolSpecialInst = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const eventDetails = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const specialInstructions = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const Martial_Arts_Type = (value) => {
  if (!value) return "This field is required";
  return null;
};

export const Number_of_Years_practiced = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const Rank = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const seminarCost = (value) => {
  if (!value) return "This field is required";
  return null;
};

// newwwwww*******************************************

export const organization_name = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const department_division = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const contact_person = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const contact_email = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const contact_phone = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const organization_address = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const city = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const state_province = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const affiliation_agreement = (value) => {
  if (!value) return "This field is required";
  return null;
};
export const schoolgym = (value) => {
  if (!value) return "This field is required";
  return null;
};


export const purpose_of_affiliation = (value) => {
  if (!value) return "This field is required";
  return null;
};


