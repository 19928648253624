import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../Redux/Action/authentication-action";

import "./dashboard.css";
import {
  MembershipData,
  PaymentdataAction,
} from "../Redux/Action/payment-action";

const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.authReducer);
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    dispatch(MembershipData());
  }, []);

  const {
    memberData,
    success: successMember,
    error: errorMember,
  } = useSelector((state) => state.MembershipReducer);

  const {
    checkoutResponse,
    success: successCheckout,
    error: errorCheckout,
  } = useSelector((state) => state.PaymentReducer);
  // const url = checkoutResponse?.sessionId?.url;
  // console.log("url", url);
  const handleClick = (data) => {
    dispatch(PaymentdataAction({ data: data, user_id: userData.user_id }));
  };
  useEffect(() => {
    const url = checkoutResponse?.sessionId?.url;
    if (url) {
      window.location.href = url;
    }
  }, [successCheckout]);

  return (
    <>
      {/* <div className="HomepageMain d-flex">
        <div className="cms-logo ">
          <Link to={"/dashboard"}>
            <img className="logo-dashboard  dash" src="/img/logo.png" />
          </Link>
        </div>

        <div class="center mt-2 thankyoudiv">
          <Link className="hover-primary" to="/password-change">
            Update password?
          </Link>
          <button className="successLogout" onClick={() => logoutHandler()}>
            Logout
          </button>
        </div>
      </div> */}
      <div class="homepage">
        <div class="homepagepay">
          <div class="thanktexthome">
            <p className="Subscription">Choose a Subscription Pack </p>

            <div className="paynowsec">
              {memberData?.results
                ?.sort((a, b) => a.price - b.price)
                ?.map?.((item) => (
                  <div className="card paynowbtn">
                    <img className="payImg" src="/img/Colored icon.png" />
                    <h2 id="membershipHeading">{item.membership_type}</h2>
                    <h3 className="price">${item.price}</h3>
                    <p key={item.id} onClick={() => handleClick(item.id)}>
                      Pay Now
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPage;
