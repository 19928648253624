// export const BACKEND_URL = "http://122.160.74.251:8014/";
// export const BACKEND_URL = "http://18.210.14.29:8000/";
// export const BACKEND_URL = "http://100.27.12.115:8000/";

// export const BACKEND_URL = "http://122.160.74.251:8015/";

// export const Frontend_URL = "http://122.160.74.251:3014/";
export const BACKEND_URL = "https://dev.geothara.com/";

//export const Frontend_URL = "http://3.133.238.36:3000/";
export const Frontend_URL = "https://geothara.com/";
