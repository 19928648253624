import React from "react";
import { Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <>
      <div class="thankyoupage">
        <div class="login-content-registering">
          <div class="thanktext">
            <div className="cms-logo">
              <img className="logo-thankyou" src="/img/logo.png" />
            </div>
            <p>
              To complete your account registration, please click on the
              verification link sent to your email.
            </p>
            <div class="center mt-2 thankyoudiv">
              <Link className="successfullyButton2" to="/login">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
