import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../Redux/Action/authentication-action";
import { useDispatch } from "react-redux";
import Newfile from "./newfile";
import Graphpage from "../Components/graph";
import Userlist from "./userList";
import { useSelector } from "react-redux";

const Home = () => {
  const { userData } = useSelector((state) => state.authReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMethod, setSelectedMethod] = useState("1");

  return (
    <>
      <p className="Subscription">Home</p>
      <div className="col">
        <Newfile
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
        />
      </div>
    </>
  );
};

export default Home;
